// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---content-pages-imprint-mdx": () => import("./../../../content/pages/imprint.mdx" /* webpackChunkName: "component---content-pages-imprint-mdx" */),
  "component---content-pages-privacy-mdx": () => import("./../../../content/pages/privacy.mdx" /* webpackChunkName: "component---content-pages-privacy-mdx" */),
  "component---content-pages-test-mdx": () => import("./../../../content/pages/test.mdx" /* webpackChunkName: "component---content-pages-test-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */)
}

